import NiceModal from '@ebay/nice-modal-react'
import { AuthUser, getCurrentUser } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { FC, ReactNode, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import Sticky from 'react-sticky-el'

import { AccountSection } from './AccountSection'
import { Navigation } from './Navigation'
import styles from './header.module.scss'
import { ButtonHierarchy } from '@/components/styleguide/Button'
import { InternalLink } from '@/components/styleguide/Link'
import { constants, STATUS_LOGIN, STATUS_PRE_REGISTER } from '@/lib/constants'
import { useExperiment } from '@/lib/hooks/useExperiment'
import { ROUTES } from '@/lib/routes'
import { addToDataLayer } from '@/lib/utilities/vehicle'
import { postAnalyticsEvent } from '@/store/actions/event'
import { clearComparisonList, clearLocoSpaceState } from '@/store/reducers/locoSpace'
import { storeSavedSearchReference } from '@/store/reducers/search'
import {
  clearUserStateExceptRedirectUrl,
  storeIsUserAuthenticated,
  storeUserName,
} from '@/store/reducers/user'
import { Size, Theme } from '@/types/system'

const Icon = dynamic(() => import('@/components/styleguide/Icon'))
const AuthenticationModal = dynamic(() => import('@/components/styleguide/AuthenticationModal'), {
  ssr: false,
})

export type HeaderProps = {
  backButtonUrl?: string
  isVans?: boolean
  hideNav?: 'always' | 'mobile' | 'never'
  isLogoSticky?: boolean
  width?: 'full' | 'fixed' | 'content'
  theme?: Theme
  backgroundColor?: string
  navMobileRight?: ReactNode
  navDesktopCenter?: ReactNode
  hasBorderBottom?: 'always' | 'desktop' | 'mobile' | 'never'
  isContentPage?: boolean
}

export const Header = ({
  backButtonUrl = '',
  isVans = false,
  theme = Theme.light,
  backgroundColor = 'grey',
  hideNav = 'never',
  isLogoSticky = false,
  width = 'full',
  navMobileRight,
  navDesktopCenter,
  hasBorderBottom = 'never',
}: HeaderProps) => {
  const dispatch = useDispatch()
  const [experimentGroup] = useExperiment()
  const isDesktop = useMediaQuery({ minWidth: 992 })
  const isDisplay = useMediaQuery({ minWidth: 1260 })

  const trackEventsInAnalytics = ({
    event,
    link = undefined,
  }: {
    event: string
    link: undefined | string
  }) => {
    addToDataLayer({
      event: 'main-navigation',
      'nav-event': event,
      'nav-link': link,
      experimentGroup,
    })
    dispatch(
      postAnalyticsEvent({
        event: constants.eventTypes.mainNavigation,
        payload: {
          'nav-event': event,
          'nav-link': link,
        },
      }),
    )
  }

  const onClickToLogin = () => {
    trackEventsInAnalytics({ event: 'nav-link-click', link: 'log in' })
    NiceModal.show(AuthenticationModal as FC, {
      initialStatus: STATUS_LOGIN,
    })
  }

  const onClickToSignUp = () => {
    trackEventsInAnalytics({ event: 'nav-link-click', link: 'sign up' })
    NiceModal.show(AuthenticationModal as FC, {
      initialStatus: STATUS_PRE_REGISTER,
    })
  }

  const onToggleAccountNav = () => {
    trackEventsInAnalytics({ event: 'nav-link-click', link: 'account' })
  }

  const getThemeColor = (backgroundColor: string) => {
    switch (backgroundColor) {
      case 'grey':
      case 'primary-yellow':
      case 'white':
        return '#FFF'
      case 'transparent':
        return '#3a0642'
      case 'backdrop':
        return '#F2F2F7'
      default:
        return '#FFF'
    }
  }

  const setUserAsLoggedIn = useCallback(
    (user: AuthUser) => {
      dispatch(storeUserName(user.username))
      dispatch(storeIsUserAuthenticated(true))
    },
    [dispatch],
  )

  const setUserAsLoggedOut = useCallback(() => {
    dispatch(clearUserStateExceptRedirectUrl())
    dispatch(clearLocoSpaceState())
    dispatch(clearComparisonList())
    dispatch(storeSavedSearchReference(''))
  }, [dispatch])

  const updateUserStatus = useCallback(() => {
    getCurrentUser()
      .then((user: AuthUser) => {
        setUserAsLoggedIn(user)
      })
      .catch(() => {
        setUserAsLoggedOut()
      })
  }, [setUserAsLoggedIn, setUserAsLoggedOut])

  useEffect(() => {
    updateUserStatus()
  }, [updateUserStatus])

  useEffect(() => {
    const hubListenerCancel = Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signedIn':
        case 'signInWithRedirect':
          return dispatch(storeIsUserAuthenticated(true))
        case 'signInWithRedirect_failure':
          return setUserAsLoggedOut()
        case 'signedOut':
          setUserAsLoggedOut()
          return dispatch(storeIsUserAuthenticated(false))
        default:
          return
      }
    })
    return () => {
      hubListenerCancel()
    }
  }, [dispatch, setUserAsLoggedOut])

  return (
    <>
      <Head>
        <meta
          name="theme-color"
          content={getThemeColor(backgroundColor)}
          key="theme-color"
        />
      </Head>
      <header
        className={styles.container}
        data-theme={theme}
        data-is-vans={isVans}
        data-background-color={backgroundColor}
        data-hide-nav={hideNav}
        data-sticky-logo={isLogoSticky}
        data-width={width}
        data-has-border-bottom={hasBorderBottom}>
        <div className={styles.top}>
          <div className={styles.sticky}>
            <Sticky
              boundaryElement=".global-scroll-area"
              stickyStyle={{ top: '0.5rem', width: 'fit-content', zIndex: 10 }}
              dontUpdateHolderHeightWhenSticky
              hideOnBoundaryHit={false}
              disabled={!isLogoSticky || !isDesktop}
              topOffset={-8}>
              <div className={styles.left}>
                {backButtonUrl && (
                  <InternalLink
                    size={Size.Large}
                    hierarchy={ButtonHierarchy.Text}
                    href={backButtonUrl}
                    hasPadding
                    isSquare
                    hasBackground
                    hasTransition>
                    <Icon
                      size={Size.Large}
                      name="ChevronLeft"
                    />
                  </InternalLink>
                )}
                <Link href={isVans ? ROUTES.vanHomePage.url : ROUTES.homePage.url}>
                  <a className={styles.logo}>
                    <Image
                      src={`${constants.imageHost.assets}/logos/${theme === Theme.dark ? 'light' : 'dark'}.svg`}
                      alt="LeaseLoco"
                      width="143"
                      height="32"
                      priority
                    />
                  </a>
                </Link>
                {isDisplay && (hideNav === 'never' || hideNav === 'mobile') && (
                  <Navigation
                    theme={theme}
                    trackEventsInAnalytics={trackEventsInAnalytics}
                  />
                )}
              </div>
            </Sticky>
          </div>
          <div className={styles.center}>{navDesktopCenter}</div>
          <div className={styles.right}>
            <MediaQuery maxWidth={991}>
              {navMobileRight ? (
                navMobileRight
              ) : (
                <AccountSection
                  theme={theme}
                  onToggleAccountNav={onToggleAccountNav}
                  onClickToSignUp={onClickToSignUp}
                  onClickToLogin={onClickToLogin}
                />
              )}
            </MediaQuery>
            <MediaQuery minWidth={992}>
              <AccountSection
                theme={theme}
                onToggleAccountNav={onToggleAccountNav}
                onClickToSignUp={onClickToSignUp}
                onClickToLogin={onClickToLogin}
              />
            </MediaQuery>
          </div>
        </div>
        {hideNav === 'never' && (
          <div className={styles.bottom}>
            <Navigation
              theme={theme}
              trackEventsInAnalytics={trackEventsInAnalytics}
            />
          </div>
        )}
      </header>
    </>
  )
}
