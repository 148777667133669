import dynamic from 'next/dynamic'
import NiceModal from '@ebay/nice-modal-react'
import { useDispatch } from 'react-redux'
import { FC } from 'react'
import { useRouter } from 'next/router'

import {
  FILTER_LEASE_TYPES_BUSINESS,
  FILTER_LEASE_TYPES_PERSONAL,
  FILTER_VEHICLE_TYPES_CARS,
  FILTER_VEHICLE_TYPES_VANS,
} from '@/lib/constants'
import { Button, ButtonHierarchy } from '@/components/styleguide/Button'
import { Size, Theme } from '@/types/system'
import { InternalLink } from '@/components/styleguide/Link'
import { clearSearchStateOnVehicleTypeChange, storeLeaseType } from '@/store/reducers/search'
import { isProd } from '@/lib/utilities/system'

import styles from './header.module.scss'

const BrandsModal = dynamic(() => import('@/components/modals/BrandsModal'), {
  ssr: false,
})

export type trackAnalyticsProps = {
  event: string
  link: string
}

export type NavigationProps = {
  trackEventsInAnalytics: ({ event, link }: trackAnalyticsProps) => void
  theme?: Theme
}

export const Navigation = ({
  trackEventsInAnalytics,
  theme = Theme.light,
}: NavigationProps) => {
  const dispatch = useDispatch()
  const router = useRouter()

  const onToggleBrands = () => {
    trackEventsInAnalytics({ event: 'nav-link-click', link: 'brands' })
    NiceModal.show(BrandsModal as FC)
  }

  const onToggleDealNav = (vehicleType: number) => {
    trackEventsInAnalytics({ event: 'nav-link-click', link: `${vehicleType} leasing` })
    dispatch(
      storeLeaseType(
        vehicleType === FILTER_VEHICLE_TYPES_VANS
          ? FILTER_LEASE_TYPES_BUSINESS
          : FILTER_LEASE_TYPES_PERSONAL,
      ),
    )
    dispatch(clearSearchStateOnVehicleTypeChange(vehicleType))
  }

  return (
    <nav className={styles.nav}>
      <ul>
        <li>
          <InternalLink
            size={Size.Small}
            hierarchy={ButtonHierarchy.Outline}
            href="/car-leasing/search"
            theme={theme}
            onClick={() => {
              trackEventsInAnalytics({ event: 'nav-link-click', link: 'car-leasing' })
              onToggleDealNav(FILTER_VEHICLE_TYPES_CARS)
            }}
            className={`${router.asPath.includes('/car-leasing/search')
              ? styles.active
              : ''
              }${!isProd() ? ' header__car-leasing-button' : ''}`}
            hasPadding
            hasTransition
            hasBackground>
            Car leasing
          </InternalLink>
        </li>
        <li>
          <InternalLink
            size={Size.Small}
            hierarchy={ButtonHierarchy.Outline}
            href="/van-leasing/search"
            theme={theme}
            onClick={() => {
              trackEventsInAnalytics({ event: 'nav-link-click', link: 'van-leasing' })
              onToggleDealNav(FILTER_VEHICLE_TYPES_VANS)
            }}
            className={`${router.asPath.includes('/van-leasing/search')
              ? styles.active
              : ''
              }${!isProd() ? ' header__van-leasing-button' : ''}`}
            hasPadding
            hasTransition
            hasBackground>
            Van leasing
          </InternalLink>
        </li>
        <li>
          <InternalLink
            size={Size.Small}
            hierarchy={ButtonHierarchy.Outline}
            href="/car-leasing/electric-car-leasing"
            theme={theme}
            onClick={() => {
              trackEventsInAnalytics({ event: 'nav-link-click', link: 'electric' })
            }}
            className={`${router.asPath.includes('/car-leasing/electric-car-leasing')
              ? styles.active
              : ''
              }${!isProd() ? ' header__electric-leasing-button' : ''}`}
            hasPadding
            hasTransition
            hasBackground>
            Electric
          </InternalLink>
        </li>
        <li>
          <Button
            size={Size.Small}
            hierarchy={ButtonHierarchy.Outline}
            onClick={onToggleBrands}
            className={!isProd() ? 'header__brands-button' : ''}
            iconPosition="right"
            hasPadding
            hasBackground
            theme={theme}
            icon="ChevronDown"
            label="Brands" />
        </li>
      </ul>
    </nav>
  )
}
